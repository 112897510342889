import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import leadsSlice from '../features/leads/leadSlice'
import userDataSlice from '../features/common/userDataSlice'
import carouselSlice from '../features/user/slice/carouselSlice'
import gallerySlice from '../features/user/slice/gallerySlice'
import directorSlice from '../features/user/slice/directorSlice'
import principalSlice from '../features/user/slice/principalSlice'
import missionSlice from '../features/user/slice/missionSlice'
import visionSlice from '../features/user/slice/visionSlice'
import whyChooseSlice from '../features/user/slice/whyChooseSlice'
import coursesSlice from '../features/user/slice/courseSlice'
import eventSlice from '../features/user/slice/eventSlice'
import teacherSlice from '../features/user/slice/teacherSlice'
import testimonialSlice from '../features/user/slice/testimonialSlice'
import researchSlice from '../features/user/slice/researchSlice'
import resourceSlice from '../features/user/slice/resourceSlice'
import bankSlice from '../features/user/slice/bankSlice'
import recentactivitySlice from '../features/user/slice/recentActivitySlice'
import studymaterialslice from '../features/user/slice/studyMasterialSlice'
import homeMetaSlice from "../features/user/slice/homeMetaSlice"
import aboutMetaSlice from "../features/user/slice/aboutMetaSlice"
import contactMetaSlice from "../features/user/slice/contactMetaSlice"
import courseMetaSlice from "../features/user/slice/courseMetaSlice"
import galleryMetaSlice from "../features/user/slice/galleryMetaSlice"
import notificationMetaSlice from "../features/user/slice/notificationMetaSlice"
import homeCaroselSlice from "../features/user/slice/homeCaroselSlice"
import aboutCaroselSlice from "../features/user/slice/aboutCaroselSlice"
import notificationSlice from "../features/user/slice/notificationSlice"
import metricSlice from "../features/user/slice/metricSlice"
import youtubeSlice from '../features/user/slice/youtubeSlice'
import videoTestimonialSlice from '../features/user/slice/videoTestimonialSlice'
import weSupportSlice from '../features/user/slice/weSupportSlice'
import courseDetailsSlice from '../features/user/slice/courseDetailsSlice'
import contactSlice from '../features/user/slice/contactSlice'
import homeTextSlice from '../features/user/slice/homeTextSlice'
import facultySlice from '../features/user/slice/facultySlice'
import aboutTextSlice from '../features/user/slice/aboutTextSlice'
import gfcPageTextSlice from '../features/user/slice/gfcPageTextSlice'


const combinedReducer = {
  header : headerSlice,
  rightDrawer : rightDrawerSlice,
  modal : modalSlice,
  lead : leadsSlice,
  userDataSlice :userDataSlice,
  carousel : carouselSlice,
  gallery : gallerySlice,
  director : directorSlice,
  principal : principalSlice,
  mission : missionSlice,
  vision : visionSlice,
  WhyChoose: whyChooseSlice,
  course : coursesSlice,
  event : eventSlice,
  teacher : teacherSlice,
  testimonial : testimonialSlice,
  research : researchSlice,
  resource : resourceSlice,
  bank:bankSlice,
  recentActivity:recentactivitySlice,
  studymaterial:studymaterialslice,
  homeMeta:homeMetaSlice,
  aboutMeta : aboutMetaSlice,
  contactMeta: contactMetaSlice,
  courseMeta: courseMetaSlice,
  galleryMeta: galleryMetaSlice,
  notificationMeta: notificationMetaSlice,
  homeCarosel:homeCaroselSlice,
  aboutCarosel:aboutCaroselSlice,
  notification:notificationSlice,
  metric:metricSlice,
  youtube:youtubeSlice,
  videoTestimonial:videoTestimonialSlice,
  weSupport:weSupportSlice,
  courseDetail:courseDetailsSlice,
  contact:contactSlice,
  homeText:homeTextSlice,
  faculty:facultySlice,
  aboutText:aboutTextSlice,
  gfcPageText:gfcPageTextSlice
}

export default configureStore({
    reducer: combinedReducer
})